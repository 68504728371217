<template>
  <div class="mx-auto">
    <b-form-group label-for="tags-with-dropdown">
      <label class="mb-1 ml-3">Thêm người tham dự cuộc họp:</label>
      <div class="d-flex">
        <treeselect
          :multiple="true"
          :options="options"
          :value-consists-of="valueConsistsOf"
          v-model="userArray"
          placeholder="Vui chọn người bạn muốn mời"
          noResultsText="Không tìm thấy người dùng"
        />

        <button
          v-b-tooltip.hover
          title="Thêm người vào cuộc họp"
          v-if="this.$route.name !== 'meetingRoom'"
          type="button"
          class="btn btn-light ml-2 mr-2"
          v-on:click="addParticipantsToMeeting"
        >
          <i class="fa fa-plus" style="color: #4caf50"></i>
        </button>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { errorNotification } from "@/core/helpers/notification";
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ApiService from "@/core/services/api.service.js";
import { mapGetters } from "vuex";
import { RELOAD_COUNT } from "@/core/services/store/store";

export default {
  name: "input-send-email",
  data() {
    return {
      userArray: [],
      options: [],
      valueConsistsOf: "LEAF_PRIORITY",
    };
  },
  components: {
    Treeselect,
  },
  props: {
    bookingInfo: {
      type: Object,
    },
    meetingArray: {
      type: Array,
    },
    getListOfUserPressentInTheMeeting: {
      type: Function,
    },
    userListInParticipant: {
      type: Array,
    },
    seats: {
      type: Number,
    },
    meetingInfo: {
      type: Object,
    },
    payLoadFromInputSendEmail: {
      type: Function,
    },
  },

  methods: {
    async addParticipantsToMeeting() {
      if (this.userArray.length === 0)
        return errorNotification("Không có ai để mời vào phòng !");
      const payload = {
        userArray: this.userArray,
        meetingArray: this.meetingArray,
      };

      await ApiService.post("/meeting/addParticipantsToMeeting", payload).then(
        async () => {
          await ApiService.put("/bookinginfo/inviteMoreGuest", {
            userArray: this.userArray,
            eventId: this.meetingInfo.eventId,
          });

          this.$store.dispatch(RELOAD_COUNT);
        }
      );
    },
    getUserList() {
      ApiService.post("/getUserByDepartment", {
        arrUserInMeeting: this.userListInParticipant,
        userIdLogin: this.currentUser.id,
      }).then((db) => {
        const { data } = db.data;

        const fliteredData = data.filter((item) => item.Users.length > 0);

        const newResult = fliteredData.map((item) => {
          return {
            id: item.departmentName + "-" + item.id,
            label: item.departmentName,
            children: item.Users.map((user) => {
              return {
                id: user.id,
                label: `${user.fullname} ${
                  user.email ? `(${user.email})` : ""
                }`,
              };
            }),
          };
        });

        this.options = newResult;
      });
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  mounted() {
    this.getUserList();
  },
  watch: {
    userArray(newUserArray) {
      this.payLoadFromInputSendEmail(newUserArray);
    },
    userListInParticipant() {
      this.getUserList();
    },
  },
};
</script>
<style scoped>
li.list-inline-item {
  min-height: 40px;
}
.dropdown-menu.w-100.show li {
  min-height: 30px;
}
ul.dropdown-menu.w-100.show {
  background: rgb(243, 246, 249);
}
.tooltip {
  opacity: 1;
}
</style>
